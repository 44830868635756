import { UrlTemplates } from "checkspa/constants";
import { Check } from "checkspa/ui/routes/Check";
import { Landing } from "checkspa/ui/routes/Landing";
import { LoadableComponent, LoadingMessage } from "library/loader";
import * as React from "react";
import { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSmartVerification } from "./hooks/useSmartVerification";

const DocumentationAreaAsync = LoadableComponent({
  loader: () =>
    import(
      /* webpackChunkName: "DocumentationCheck" */ "checkspa/ui/routes/DocumentationCheck"
    ).then((mod) => mod.DocumentationArea),
});

const PackagingCheckAsync = LoadableComponent({
  loader: () =>
    import(
      /* webpackChunkName: "PackagingCheck" */ "checkspa/ui/routes/PackagingCheck"
    ).then((mod) => mod.PackagingCheck),
});

const VerificationSv = lazy(() =>
  import(
    /* webpackChunkName: "VerificationSv" */ "checkspa/ui/routes/VerificationSv"
  ).then(({ Verification }) => ({ default: Verification }))
);
const Verification = lazy(() =>
  import(
    /* webpackChunkName: "Verification" */ "checkspa/ui/routes/Verification"
  ).then(({ Verification }) => ({ default: Verification }))
);

function VerificationAsync() {
  var smartVerification = useSmartVerification();

  return (
    <Suspense fallback={<LoadingMessage />}>
      {smartVerification ? <VerificationSv /> : <Verification />}
    </Suspense>
  );
}

const DigitalDgdScreenAsync = LoadableComponent({
  loader: () =>
    import(/* webpackChunkName: "Xsdg" */ "checkspa/ui/routes/DigitalDgd").then(
      (mod) => mod.DigitalDgd
    ),
});

const AddRowScreenSv = lazy(() =>
  import(
    /* webpackChunkName: "VerificationSv" */ "checkspa/ui/routes/AddRowScreenSv"
  ).then(({ AddRowScreen }) => ({ default: AddRowScreen }))
);
const AddRowScreen = lazy(() =>
  import(
    /* webpackChunkName: "Verification" */ "checkspa/ui/routes/AddRowScreen"
  ).then(({ AddRowScreen }) => ({ default: AddRowScreen }))
);

function AddRowScreenAsync() {
  var smartVerification = useSmartVerification();

  return (
    <Suspense fallback={<LoadingMessage />}>
      {smartVerification ? <AddRowScreenSv /> : <AddRowScreen />}
    </Suspense>
  );
}

const SignOffScreenAsync = LoadableComponent({
  loader: () =>
    import(/* webpackChunkName: "SignOff" */ "checkspa/ui/routes/SignOff").then(
      (mod) => mod.SignOffScreen
    ),
});

const ReportScreenAsync = LoadableComponent({
  loader: () =>
    import(/* webpackChunkName: "SignOff" */ "checkspa/ui/routes/Report").then(
      (mod) => mod.ReportScreen
    ),
});

declare namespace App {
  export interface Props extends React.Props<any> {}

  export interface State {
    /* empty */
  }
}

export class App extends React.Component<App.Props, App.State> {
  render() {
    return (
      <Check>
        <Switch>
          <Route path="/" exact component={Landing} />
          {/* START redirects for old URLs */}
          <Redirect from="/overview" to="/verification" exact />
          <Redirect from="/identification" to="/documentation" exact />
          <Route
            exact
            path="/identification/:id?/:section?"
            render={({ match }) =>
              match.params.section ? (
                <Redirect
                  to={`/documentation/${match.params.id}/${match.params.section}`}
                />
              ) : (
                <Redirect to={`/documentation/${match.params.id}`} />
              )
            }
          />
          {/* END redirects for old URLs */}
          <Route
            path="/documentation/:id?/:section?"
            component={DocumentationAreaAsync}
          />
          <Route
            path="/packaging/:id?/:section?"
            component={PackagingCheckAsync}
          />
          <Route path="/signoff" component={SignOffScreenAsync} />
          <Route path={UrlTemplates.reportUrl} component={ReportScreenAsync} />
          <Route path="/dgd" exact component={DigitalDgdScreenAsync} />
          <Redirect path="/verification" to="/verify" exact />
          <Route path="/verify" component={VerificationAsync} />
          <Route path="/editor/add" component={AddRowScreenAsync} />
        </Switch>
      </Check>
    );
  }
}
