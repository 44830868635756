/**
 * Digital DGDs cannot be edited or verified, they come as they are and cannot be changed
 * I am avoiding the term "read-only" because there are times when
 * an editable DGD can be read-only based on ownership etc
 * @param sourceType The source of the DGD
 */
export function isDigitalDgd(sourceType: DgdSourceType) {
  return sourceType != 'Ocr' && sourceType != 'Manual';
}

/**
 * Is the DGD source the type that can be verified
 * @param sourceType The source of the DGD
 */
export function isVerifiableTypeOfDgd(sourceType: DgdSourceType) {
  return sourceType == 'Ocr' || sourceType == 'Manual';
}
