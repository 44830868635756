import { asyncRequest } from "communication/async-request";
import { Dispatch } from "redux";
import { createAction } from "redux-actions";
import { Actions } from "../../constants";

const updateNotePressed = createAction<UserNote>(Actions.UPDATE_NOTE_PRESSED);
const updateNoteConfirmed = createAction<{ note: UserNote }>(
  Actions.UPDATE_NOTE_CONFIRMED
);
const updateNoteFailed = createAction<UserNote>(Actions.UPDATE_NOTE_ERROR);

export function updateNote(
  userNote: UserNote,
  saveUrl: string
): (dispatch: Dispatch<ReduxActions.Action<any>>) => void {
  return (dispatch: Dispatch<ReduxActions.Action<any>>) => {
    dispatch(updateNotePressed(userNote));

    var body = {
      noteContents: userNote.notes,
      rowId: userNote.rowId,
      identifier: userNote.identifier,
    };

    var failureMsg = {
      rowId: body.rowId,
      identifier: body.identifier,
    } as UserNote;

    asyncRequest({
      url: saveUrl,
      method: "POST",
      data: body,
      callback: function (response: jsendResponse) {
        switch (response.status) {
          case "success":
            dispatch(updateNoteConfirmed(response.data));
            break;
          case "unauthorised":
            dispatch(updateNoteFailed(failureMsg));
            break;
          default:
            alert("Error saving note, please try again");
            dispatch(updateNoteFailed(failureMsg));
            break;
        }
      },
    });
  };
}
